import React, { Component } from 'react'
import { navigate } from 'gatsby'

import InfoBox from '../../info-box'

class Existing extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = (value) => {
    this.props.submitData({ existing: value })

    if (this.props.landing) {
      let path = '/apply/dobmonth'
      this.props.toggleBypassZip(true)
      this.props.toggleIncludeAddress(true)

      // if (this.props.type === "medicareAlt") {
      //   path = "/apply/dobmonth"
      //   this.props.toggleIncludeAddress(true)
      //   this.props.changeType(this.props.type)
      // }

      this.props.nextStep('zip')

      navigate(path)
    } else {
      this.props.handleNext('existing')
    }
  }

  render = () => {
    const {
      landing,
      title = 'Are you currently enrolled <br /> in Medicare parts A &amp; B?',
    } = this.props
    return (
      <div>
        {!landing && (
          <InfoBox text="Great! First, just answer a few simple questions for us." />
        )}
        <strong
          className="h4"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <div className="row">
          <div className="col full-width inline-buttons">
            <button
              className="btn"
              onClick={() => {
                this.handleSubmit('No')
              }}
            >
              No
            </button>
            <button
              className="btn"
              onClick={() => {
                this.handleSubmit('Yes')
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Existing
