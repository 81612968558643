import React, { Component } from 'react'

import InfoBox from '../../info-box'

class Tobacco extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit = (value) => {
    this.props.submitData({ tobacco: value })
    this.props.handleNext('tobacco')
  }

  render = () => (
    <div>
      <InfoBox text="We have many affordable plans to choose from!" />
      <strong className="h4">Do you use tobacco?</strong>
      <div className="row">
        <div className="col full-width inline-buttons">
          <button
            className="btn"
            onClick={() => {
              this.handleSubmit('No')
            }}
          >
            No
          </button>
          <button
            className="btn"
            onClick={() => {
              this.handleSubmit('Yes')
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default Tobacco
