import React from 'react'
import { connect } from 'react-redux'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import UseInsurance from '../components/sections/use-insurance'
import Partners from '../components/sections/partners'
import Main from '../components/apply/main'
import Header from '../components/header'

import SingleBg from '../images/header-single-bg.svg'

const ApplyPage = ({ location, captive }) => {
  let ctaAlt = false
  let headerClassName = ''
  let introProps = {
    showTitle: false,
    colType: 'full-col',
  }

  if (captive && typeof captive.altHeaderLayout !== 'undefined') {
    ctaAlt = captive.altHeaderLayout
    headerClassName = captive.altHeaderLayout ? 'large' : ''
    introProps = {
      ...introProps,
      bgImage: SingleBg,
      classNames: 'single',
    }
  }

  return (
    <Layout phone="855-504-2764" location={location}>
      <SEO
        title="Apply for Health Insurance"
        keywords={[`health`, `insurance`, `plans`, `affordable`, `supplement`]}
      />
      <Header phone="855-504-2764" ctaAlt className={headerClassName} />
      <Intro {...introProps}>
        <Main location={location} />
      </Intro>
      <main id="main">
        <Partners />
        <UseInsurance />
      </main>
    </Layout>
  )
}

export default connect((state) => state, {})(ApplyPage)
